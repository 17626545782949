import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Sidebar.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import {CategoryToolList} from "../../utils/CategoryToolList";

interface SidebarProps {
    collapsed: boolean;
    onToggleCollapse: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ collapsed, onToggleCollapse }) => {
    const location = useLocation();

    const menuItems = [
        {
            name: 'Home',
            icon: faHome,
            path: '/',
        },
        ...Object.values(CategoryToolList).map(category => ({
            name: category.name,
            icon: category.icon,
            path: category.path,
        })),
    ];

    return (
        <aside
            className={`${styles.sidebar} ${collapsed ? styles.sidebarCollapsed : ''}`}
            aria-label="Sidebar"
        >

            {/* Menu */}
            <nav className={styles.menu} aria-label="Main Navigation">
                <ul className={styles.menuList}>
                    {menuItems.map((item) => (
                        <li
                            key={item.name}
                            className={`${styles.menuItem} ${
                                (location.pathname !== '/' && (location.pathname.startsWith(item.path) && item.path !== '/')) ? styles.active : (location.pathname === '/' && item.path === '/') ? styles.active : ''
                            }`}
                        >
                            <Link
                                to={item.path}
                                className={styles.menuLink}
                                title={collapsed ? item.name : undefined}
                            >
                                <FontAwesomeIcon icon={item.icon} className={styles.icon}/>
                                <span className={styles.menuText}>{item.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>

            {/* Collapse Button at Bottom */}
            <div className={styles.sidebarFooter}>
                <button
                    onClick={onToggleCollapse}
                    className={styles.collapseButton}
                    aria-label={collapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
                    aria-expanded={!collapsed}
                >
                    <FontAwesomeIcon
                        icon={collapsed ? faAngleDoubleRight : faAngleDoubleLeft}
                        className={styles.collapseIcon}
                    />
                </button>
            </div>
        </aside>
    );
};

export default Sidebar;
