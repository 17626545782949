import React, { useState } from 'react';
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import Footer from '../Footer';
import styles from './Layout.module.css';
import {ToastContainer} from "react-toastify";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const [collapsed, setCollapsed] = useState<boolean>(
        window.innerWidth < 768 ? true : false // Set initial state based on screen width
    );

    const handleSidebarToggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className={styles.appContainer}>
            <TopBar />
            <div className={styles.mainContent}>
                <Sidebar collapsed={collapsed} onToggleCollapse={handleSidebarToggle} />
                <div
                    className={`${styles.contentArea} ${
                        collapsed ? styles.contentAreaCollapsed : ''
                    }`}
                >
                    <div className={styles.childContent}>
                    {children}
                    </div>
                    <Footer />
                </div>

            </div>
            <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
        </div>
    );
};

export default Layout;
