import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import CategoryPage from '../pages/CategoryPage';
import NotFoundPage from '../pages/NotFoundPage';
import Layout from '../components/Layout';
import PasswordStrengthChecker from "../tools/Password/StrengthChecker";
import {JSONFormatter} from "../tools/Json/Formatter";
import {JSONViewer} from "../tools/Json/Viewer";
import {JSONComparator} from "../tools/Json/Comparator";
import {PasswordGenerator} from "../tools/Password/Generator";
import {TextBase64} from "../tools/Text/Base64";
import {UtilitiesNotes} from "../tools/Utilities/Notes";
import JWTDecoder from '../tools/Jwt/Decoder';

const AppRoutes: React.FC = () => {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route
                      path="/password/generate"
                      element={<PasswordGenerator />}
                    />
                    <Route
                      path="/password/strength-checker"
                      element={<PasswordStrengthChecker />}
                    />
                    <Route
                        path="/password/strength-checker"
                        element={<PasswordStrengthChecker />}
                    />
                    <Route
                        path="/json/formatter"
                        element={<JSONFormatter />}
                    />
                    <Route
                        path="/json/viewer"
                        element={<JSONViewer />}
                    />
                    <Route
                        path="/json/comparator"
                        element={<JSONComparator />}
                    />
                    <Route
                        path="/text/base64"
                        element={<TextBase64 />}
                    />
                    <Route
                        path="/utilities/notes"
                        element={<UtilitiesNotes />}
                    />
                    <Route
                      path="/jwt/decoder"
                      element={<JWTDecoder />}
                    />
                    <Route
                      path="/:category"
                      element={<CategoryPage />}
                    />
                    <Route
                      path="/"
                      element={<HomePage />}
                    />
                    <Route
                      path="*"
                      element={<NotFoundPage />}
                    />
                </Routes>
            </Layout>
        </Router>
    );
};

export default AppRoutes;
