// src/components/SEO/index.tsx
import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaTag {
    charset?: string;
    name?: string;
    property?: string;
    content?: string;
}

interface SEOProps {
    title: string;
    description: string;
    keywords?: string;
    author?: string;
    image?: string;
    url?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    ogUrl?: string;
    twitterTitle?: string;
    twitterDescription?: string;
    twitterImage?: string;
    canonical?: string;
    structuredData?: string;
    meta?: MetaTag[];
}

export const SEO: React.FC<SEOProps> = ({
                                            title,
                                            description,
                                            keywords,
                                            author,
                                            image,
                                            url,
                                            ogTitle,
                                            ogDescription,
                                            ogImage,
                                            ogUrl,
                                            twitterTitle,
                                            twitterDescription,
                                            twitterImage,
                                            canonical,
                                            structuredData,
                                            meta = [],
                                        }) => {
    const metaTags: MetaTag[] = [
        { name: 'description', content: description },
        ...(keywords ? [{ name: 'keywords', content: keywords }] : []),
        ...(author ? [{ name: 'author', content: author }] : []),
        ...(image ? [{ name: 'image', content: image }] : []),
        ...(url ? [{ name: 'url', content: url }] : []),
        // Open Graph Meta Tags
        ...(ogTitle ? [{ property: 'og:title', content: ogTitle }] : []),
        ...(ogDescription ? [{ property: 'og:description', content: ogDescription }] : []),
        ...(ogImage ? [{ property: 'og:image', content: ogImage }] : []),
        ...(ogUrl ? [{ property: 'og:url', content: ogUrl }] : []),
        // Twitter Card Meta Tags
        ...(twitterTitle ? [{ name: 'twitter:title', content: twitterTitle }] : []),
        ...(twitterDescription
            ? [{ name: 'twitter:description', content: twitterDescription }]
            : []),
        ...(twitterImage ? [{ name: 'twitter:image', content: twitterImage }] : []),
        // Additional Meta Tags
        ...meta,
    ];

    return (
        <Helmet>
            <title>{title}</title>
            {metaTags.map((tag, index) => {
                if (tag.charset) {
                    return <meta key={index} charSet={tag.charset} />;
                }
                return (
                    <meta
                        key={index}
                        {...(tag.name ? { name: tag.name } : {})}
                        {...(tag.property ? { property: tag.property } : {})}
                        content={tag.content}
                    />
                );
            })}
            {canonical && <link rel="canonical" href={canonical} />}
            {structuredData && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: structuredData }}
                />
            )}
        </Helmet>
    );
};
