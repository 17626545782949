import React from 'react';
import styles from './TopBar.module.css';

const TopBar: React.FC = () => {
    return (
        <div className={styles.topBar}>
            <div className={styles.logoContainer}>
                <img src="/assets/logo.png" alt="Logo" className={styles.logo} />
            </div>
        </div>
    );
};

export default TopBar;
