// src/tools/Formatter/JSONFormatter/index.tsx
import React, { useState, useRef, useEffect } from 'react';
import styles from './JsonFormatter.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faCode,
    faExpand,
    faCompress,
    faPlus,
    faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { CollapsibleJSON } from './CollapsibleJson';
import {Hero} from "../../../components/Hero";
import {SEO} from "../../../components/SEO";

type JSONValue =
    | string
    | number
    | boolean
    | null
    | { [key: string]: JSONValue }
    | JSONValue[];

type ExpandCollapseAction = {
    action: 'expand' | 'collapse';
    id: number;
};

export const JSONFormatter: React.FC = () => {
    const [inputJSON, setInputJSON] = useState('');
    const [formattedJSON, setFormattedJSON] = useState<JSONValue | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [expandAll, setExpandAll] = useState<ExpandCollapseAction | null>(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const outputRef = useRef<HTMLDivElement>(null);

    const formatJSON = () => {
        try {
            const parsed = JSON.parse(inputJSON);
            setFormattedJSON(parsed);
            setErrorMessage('');
            setExpandAll({ action: 'expand', id: Date.now() }); // Reset to expanded state after formatting
        } catch (e) {
            setFormattedJSON(null);
            if (e instanceof SyntaxError) {
                setErrorMessage(e.message);
                toast.error(`JSON Parse Error: ${e.message}`);
            } else {
                setErrorMessage('An unknown error occurred.');
                toast.error('An unknown error occurred.');
            }
        }
    };

    const copyToClipboard = () => {
        if (formattedJSON) {
            const formattedText = JSON.stringify(formattedJSON, null, 2);
            navigator.clipboard.writeText(formattedText).then(() => {
                toast('Formatted JSON copied to clipboard!');
            });
        }
    };

    const handleExpandAll = () => {
        setExpandAll({ action: 'expand', id: Date.now() });
    };

    const handleCollapseAll = () => {
        setExpandAll({ action: 'collapse', id: Date.now() });
    };

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            // Enter full-screen mode
            if (outputRef.current) {
                outputRef.current.requestFullscreen().catch((err) => {
                    toast.error(`Error attempting to enable full-screen mode: ${err.message}`);
                });
                setIsFullScreen(true);
            }
        } else {
            // Exit full-screen mode
            if (document.fullscreenElement) {
                document.exitFullscreen();
                setIsFullScreen(false);
            }
        }
    };

    useEffect(() => {
        // Listen for the fullscreenchange event to update the state
        const handleFullScreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);

    return (
        <div className={styles.jsonFormatter}>
            {/* SEO Meta Tags */}
            <SEO
                title="JSON Formatter | CyberZero AI"
                description="Format and beautify your JSON data with CyberZero AI's free JSON Formatter tool. Enhance your development efficiency today."
                keywords="JSON Formatter, JSON Beautifier, CyberZero AI, Developer Tools, Format JSON, JSON Parser"
                author="Karthik Ramesh"
                image="/assets/json/formatter/json-formatter-banner.png"
                url="https://apps.cyberzero.ai/json/formatter"
                ogTitle="JSON Formatter | CyberZero AI"
                ogDescription="Format and beautify your JSON data with CyberZero AI's free JSON Formatter tool."
                ogImage="/assets/json/formatter/json-formatter-banner.png"
                ogUrl="https://apps.cyberzero.ai/json/formatter"
                twitterTitle="JSON Formatter | CyberZero AI"
                twitterDescription="Format and beautify your JSON data with CyberZero AI's free JSON Formatter tool."
                twitterImage="/assets/json/formatter/json-formatter-banner.png"
                canonical="https://apps.cyberzero.ai/json/formatter"
                structuredData={JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "WebApplication",
                    "name": "JSON Formatter",
                    "url": "https://apps.cyberzero.ai/json/formatter",
                    "description": "Format and beautify your JSON data with CyberZero AI's free JSON Formatter tool.",
                    "applicationCategory": "DeveloperApplication",
                    "operatingSystem": "All",
                    "author": {
                        "@type": "Person",
                        "name": "Karthik Ramesh"
                    },
                    "image": "/assets/json/formatter/json-formatter-banner.png"
                })}
                meta={[
                    { charset: 'utf-8' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:locale', content: 'en_US' },
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:site', content: '@CyberZero.AI' },
                    { name: 'twitter:creator', content: '@CyberZero.AI' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'theme-color', content: '#5e17eb' },
                    { name: 'application-name', content: 'CyberZero AI' },
                    { name: 'apple-mobile-web-app-capable', content: 'yes' },
                ]}
            />

            {/* Hero Section */}
            <Hero
                title="JSON Formatter"
                description="Format and beautify your JSON data easily."
                imageUrl="/assets/json/formatter/json-formatter-banner.png"
                imageAlt="JSON Formatter"
            />

            {/* JSON Formatter Form */}
            <div className={styles.jsonFormatterForm}>
                {/* Input Area */}
                <textarea
                    className={styles.inputArea}
                    value={inputJSON}
                    onChange={(e) => setInputJSON(e.target.value)}
                    placeholder="Enter JSON here"
                ></textarea>
                <button className={styles.formatButton} onClick={formatJSON}>
                    <FontAwesomeIcon icon={faCode} /> Format JSON
                </button>

                {errorMessage && (
                    <div className={styles.errorMessage}>
                        <p>Error: {errorMessage}</p>
                    </div>
                )}

                {formattedJSON && (
                    <div
                        className={`${styles.output} ${isFullScreen ? styles.fullScreen : ''}`}
                        ref={outputRef}
                    >
                        {/* Buttons */}
                        <div className={styles.outputButtons}>
                            <button onClick={copyToClipboard} title="Copy">
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                            <button onClick={handleExpandAll} title="Expand All">
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                            <button onClick={handleCollapseAll} title="Collapse All">
                                <FontAwesomeIcon icon={faMinus} />
                            </button>
                            <button onClick={toggleFullScreen} title="Full Screen">
                                <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} />
                            </button>
                        </div>

                        {/* Output Area */}
                        <div className={styles.outputArea}>
                            <div className={styles.outputContent}>
                                <CollapsibleJSON
                                    data={formattedJSON}
                                    expandAll={expandAll}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
