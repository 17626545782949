import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faKey,
    faShieldAlt,
    faCode,
    faFileCode,
    faEye,
    faCodeCompare,
    faTextHeight,
    faRandom,
    faNoteSticky,
    faAsterisk,
    faCheckDouble
    // faFont,
    // faExchangeAlt,
    // faLock,
    // faCompress,
    // faFileAlt,
    // faKeyboard,
    // faFingerprint,
    // faClipboardCheck,
    // faTachometerAlt,
    // faClipboardList,
    // faUnlockAlt,
    // faUserSecret,
    // faHashtag,
    // faCogs,
    // faFish,
    // faSignature,
    // faSyncAlt,
    // faLink,
    // faGlobe,
    // faEquals,
    // faFileSignature,
    // faTasks,
    // faShieldVirus,
    // faBug,
    // faNetworkWired,
    // faCog,
    // faPaperPlane,
    // faFileImport,
    // faUserLock,
    // faCalculator,
    // faBarcode,
    // faQrcode,
    // faDice,
    // faIdBadge,
    // faCompressArrowsAlt,
    // faCalendarAlt,
    // faClock,
    // faUserNinja,
    // faRobot,
    // faCookie,
    // faFileCsv,
    // faImage,
    // faInfoCircle,
    // faProjectDiagram,
    // faWaveSquare,
    // faUserShield,
    // faEyeDropper,
    // faShield,
    // faCodeBranch,
    // faObjectUngroup,
    // faServer,
    // faMapMarkerAlt,
    // faAt,
    // faChartLine,
    // faParagraph,
    // faClone,
    // faPaintBrush,
    // faRulerCombined,
    // faThLarge,
    // faNotdef,
    // faRecycle,
    // faRodSnake,
    // faArrowLeft,
    // faFileWaveform,
    // faArrowsLeftRight,
    // faMagnifyingGlass,
    // faGlobeAsia,
    // faHandshake,
    // faDotCircle,
    // faCheckCircle,
    // faLaptopCode
} from '@fortawesome/free-solid-svg-icons';

export const CategoryToolList: { [key: string]: { name: string; path: string; icon: IconDefinition; tools: { name: string; path: string; icon: IconDefinition }[] } } = {
    password: {
        name: 'Password',
        path: '/password',
        icon: faKey,
        tools: [
            { name: 'Password Generator', path: '/password/generate', icon: faKey },
            { name: 'Password Strength Checker', path: '/password/strength-checker', icon: faShieldAlt },
            // { name: 'Passphrase Generator', path: '/password/passphrase-generator', icon: faKeyboard },
            // { name: 'Password Hasher', path: '/password/hasher', icon: faFingerprint },
            // { name: 'Password Validator', path: '/password/validator', icon: faClipboardCheck },
            // { name: 'Password Meter', path: '/password/meter', icon: faTachometerAlt },
            // { name: 'Password Policy Checker', path: '/password/policy-checker', icon: faClipboardList },
            // { name: 'Password History Manager', path: '/password/history-manager', icon: faNotdef },
            // { name: 'Password Analyzer', path: '/password/analyzer', icon: faChartLine },
            // { name: 'Password Reuse Checker', path: '/password/reuse-checker', icon: faRecycle },
        ],
    },
    // encryption: {
    //     name: 'Encryption',
    //     path: '/encryption',
    //     icon: faLock,
    //     tools: [
    //         { name: 'AES Encrypt/Decrypt', path: '/encryption/aes', icon: faLock },
    //         { name: 'RSA Encrypt/Decrypt', path: '/encryption/rsa', icon: faUnlockAlt },
    //         { name: 'MD5 Hash Generator', path: '/encryption/md5', icon: faHashtag },
    //         { name: 'SHA-1 Hash Generator', path: '/encryption/sha1', icon: faCode },
    //         { name: 'SHA-256 Hash Generator', path: '/encryption/sha256', icon: faProjectDiagram },
    //         { name: 'HMAC Generator', path: '/encryption/hmac', icon: faCogs },
    //         { name: 'PBKDF2 Derivation', path: '/encryption/pbkdf2', icon: faCog },
    //         { name: 'Blowfish Encrypt/Decrypt', path: '/encryption/blowfish', icon: faFish },
    //         { name: 'Caesar Cipher Encoder/Decoder', path: '/encryption/caesar-cipher', icon: faFont },
    //         { name: 'DES Encrypt/Decrypt', path: '/encryption/des', icon: faShield },
    //         { name: 'Triple DES Encrypt/Decrypt', path: '/encryption/triple-des', icon: faShieldAlt },
    //         { name: 'RC4 Encrypt/Decrypt', path: '/encryption/rc4', icon: faRandom },
    //         { name: 'Twofish Encrypt/Decrypt', path: '/encryption/twofish', icon: faFish },
    //         { name: 'Serpent Encrypt/Decrypt', path: '/encryption/serpent', icon: faRodSnake },
    //     ],
    // },
    // hash: {
    //     name: 'Hash',
    //     path: '/hash',
    //     icon: faHashtag,
    //     tools: [
    //         { name: 'Hash Generator', path: '/hash/generator', icon: faFingerprint },
    //         { name: 'Hash Comparator', path: '/hash/comparator', icon: faBalanceScale },
    //         { name: 'String Hash Generator', path: '/hash/string-generator', icon: faSignature },
    //         { name: 'File Hash Calculator', path: '/hash/file-calculator', icon: faFileSignature },
    //         { name: 'Password Hash Generator', path: '/hash/password-generator', icon: faUserLock },
    //         { name: 'CRC32 Generator', path: '/hash/crc32', icon: faBug },
    //         { name: 'Whirlpool Hash Generator', path: '/hash/whirlpool', icon: faHashtag },
    //         { name: 'Tiger Hash Generator', path: '/hash/tiger', icon: faProjectDiagram },
    //         { name: 'RIPEMD Hash Generator', path: '/hash/ripemd', icon: faCode },
    //         { name: 'Keccak Hash Generator', path: '/hash/keccak', icon: faKey },
    //     ],
    // },
    text: {
        name: 'Text',
        path: '/text',
        icon: faTextHeight,
        tools: [
            // { name: 'Text Encoder/Decoder', path: '/text/encoder-decoder', icon: faExchangeAlt },
            { name: 'Base64 Encode/Decode', path: '/text/base64', icon: faRandom },
            // { name: 'URL Encode/Decode', path: '/text/url-encode-decode', icon: faLink },
            // { name: 'HTML Encode/Decode', path: '/text/html-encode-decode', icon: faCode },
            // { name: 'ROT13 Encoder/Decoder', path: '/text/rot13', icon: faSyncAlt },
            // { name: 'Morse Code Encoder/Decoder', path: '/text/morse-code', icon: faWaveSquare },
            // { name: 'Text Obfuscator', path: '/text/obfuscator', icon: faUserSecret },
            // { name: 'Unicode Converter', path: '/text/unicode-converter', icon: faGlobe },
            // { name: 'Case Converter', path: '/text/case-converter', icon: faTextHeight },
            // { name: 'Text Comparator', path: '/text/comparator', icon: faEquals },
            // { name: 'Leet Speak Converter', path: '/text/leet-speak', icon: faRobot },
            // { name: 'Reverse Text', path: '/text/reverse', icon: faArrowLeft },
            // { name: 'Text Statistics', path: '/text/statistics', icon: faFileWaveform },
            // { name: 'Lorem Ipsum Generator', path: '/text/lorem-ipsum', icon: faParagraph },
            // { name: 'Whitespace Remover', path: '/text/whitespace-remover', icon: faArrowsLeftRight },
        ],
    },
    json: {
        name: 'JSON',
        path: '/json',
        icon: faCode,
        tools: [
            { name: 'JSON Formatter', path: '/json/formatter', icon: faFileCode },
            { name: 'JSON Comparator', path: '/json/comparator', icon: faCodeCompare },
            // { name: 'JSON Minifier', path: '/json/minifier', icon: faCompress },
            // { name: 'JSON Validator', path: '/json/validator', icon: faClipboardCheck },
            // { name: 'JSON to CSV Converter', path: '/json/to-csv', icon: faFileCsv },
            // { name: 'CSV to JSON Converter', path: '/json/csv-to-json', icon: faFileCsv },
            // { name: 'JSON Diff Checker', path: '/json/diff-checker', icon: faTasks },
            // { name: 'JSON Schema Validator', path: '/json/schema-validator', icon: faProjectDiagram },
            // { name: 'JSON Beautifier', path: '/json/beautifier', icon: faPaintBrush },
            { name: 'JSON Decoder', path: '/json/decoder', icon: faEye },
            // { name: 'JSON Escape/Unescape', path: '/json/escape-unescape', icon: faShieldVirus },
        ],
    },
    jwt: {
        name: 'JWT',
        path: '/jwt',
        icon: faAsterisk,
        tools: [
            { name: 'JWT Decoder', path: '/jwt/decoder', icon: faCheckDouble },
        ],
    },
    // xml: {
    //     name: 'XML',
    //     path: '/xml',
    //     icon: faCodeBranch,
    //     tools: [
    //         { name: 'XML Formatter', path: '/xml/formatter', icon: faFileCode },
    //         { name: 'XML Minifier', path: '/xml/minifier', icon: faCompressArrowsAlt },
    //         { name: 'XML Validator', path: '/xml/validator', icon: faClipboardCheck },
    //         { name: 'XML to JSON Converter', path: '/xml/to-json', icon: faExchangeAlt },
    //         { name: 'JSON to XML Converter', path: '/xml/json-to-xml', icon: faExchangeAlt },
    //         { name: 'XML Beautifier', path: '/xml/beautifier', icon: faPaintBrush },
    //         { name: 'XML Decoder', path: '/xml/decoder', icon: faEye },
    //         { name: 'XML Escape/Unescape', path: '/xml/escape-unescape', icon: faShieldAlt },
    //         { name: 'XPath Tester', path: '/xml/xpath-tester', icon: faMagnifyingGlass },
    //         { name: 'XML Schema Validator', path: '/xml/schema-validator', icon: faProjectDiagram },
    //     ],
    // },
    // network: {
    //     name: 'Network',
    //     path: '/network',
    //     icon: faNetworkWired,
    //     tools: [
    //         { name: 'IP Address Converter', path: '/network/ip-converter', icon: faGlobe },
    //         { name: 'Subnet Calculator', path: '/network/subnet-calculator', icon: faCalculator },
    //         { name: 'Ping Test', path: '/network/ping-test', icon: faPaperPlane },
    //         { name: 'MAC Address Converter', path: '/network/mac-converter', icon: faBarcode },
    //         { name: 'DNS Lookup', path: '/network/dns-lookup', icon: faMagnifyingGlass },
    //         { name: 'WHOIS Lookup', path: '/network/whois-lookup', icon: faUserSecret },
    //         { name: 'Port Scanner', path: '/network/port-scanner', icon: faServer },
    //         { name: 'HTTP Header Decoder', path: '/network/http-header-decoder', icon: faGlobeAsia },
    //         { name: 'SSL Checker', path: '/network/ssl-checker', icon: faHandshake },
    //         { name: 'Traceroute', path: '/network/traceroute', icon: faDotCircle },
    //     ],
    // },
    // file: {
    //     name: 'File',
    //     path: '/file',
    //     icon: faFileAlt,
    //     tools: [
    //         { name: 'File Hash Calculator', path: '/file/hash-calculator', icon: faFileSignature },
    //         { name: 'File Encoder/Decoder', path: '/file/encoder-decoder', icon: faFileImport },
    //         { name: 'File Metadata Decoder', path: '/file/metadata-decoder', icon: faInfoCircle },
    //         { name: 'Image Steganography', path: '/file/steganography', icon: faImage },
    //         { name: 'File Encryption/Decryption', path: '/file/encryption', icon: faLock },
    //         { name: 'File Splitter/Joiner', path: '/file/splitter-joiner', icon: faObjectUngroup },
    //         { name: 'Duplicate File Finder', path: '/file/duplicate-finder', icon: faClone },
    //         { name: 'File Compression', path: '/file/compression', icon: faCompressArrowsAlt },
    //         { name: 'Checksum Verifier', path: '/file/checksum-verifier', icon: faCheckCircle },
    //         { name: 'File Format Converter', path: '/file/format-converter', icon: faExchangeAlt },
    //     ],
    // },
    // qrcode: {
    //     name: 'QR Code',
    //     path: '/qrcode',
    //     icon: faQrcode,
    //     tools: [
    //         { name: 'QR Code Generator', path: '/qrcode/generator', icon: faQrcode },
    //         { name: 'QR Code Decoder', path: '/qrcode/decoder', icon: faLaptopCode },
    //         { name: 'Barcode Generator', path: '/qrcode/barcode-generator', icon: faBarcode },
    //         { name: 'Data Matrix Generator', path: '/qrcode/data-matrix-generator', icon: faThLarge },
    //         { name: 'Aztec Code Generator', path: '/qrcode/aztec-code-generator', icon: faCheckCircle },
    //         // { name: 'PDF417 Generator', path: '/qrcode/pdf417-generator', icon: faFilePdf },
    //     ],
    // },
    // randomness: {
    //     name: 'Randomness',
    //     path: '/randomness',
    //     icon: faDice,
    //     tools: [
    //         { name: 'Random Number Generator', path: '/randomness/number-generator', icon: faDice },
    //         { name: 'UUID Generator', path: '/randomness/uuid-generator', icon: faIdBadge },
    //         { name: 'Random Password Generator', path: '/randomness/password-generator', icon: faRandom },
    //         { name: 'Random String Generator', path: '/randomness/string-generator', icon: faFont },
    //         // { name: 'Random Color Generator', path: '/randomness/color-generator', icon: faPalette },
    //         // { name: 'Coin Flip Simulator', path: '/randomness/coin-flip', icon: faCoins },
    //         // { name: 'Dice Roll Simulator', path: '/randomness/dice-roll', icon: faDiceD6 },
    //         // { name: 'Lottery Number Generator', path: '/randomness/lottery-number', icon: faTicketAlt },
    //         { name: 'Random Date Generator', path: '/randomness/date-generator', icon: faCalendarAlt },
    //         { name: 'Random Image Generator', path: '/randomness/image-generator', icon: faImage },
    //     ],
    // },
    // privacy: {
    //     name: 'Privacy',
    //     path: '/privacy',
    //     icon: faUserSecret,
    //     tools: [
    //         { name: 'Cookie Manager', path: '/privacy/cookie-manager', icon: faCookie },
    //         { name: 'Browser Fingerprinting Test', path: '/privacy/fingerprinting-test', icon: faFingerprint },
    //         { name: 'WebRTC Leak Test', path: '/privacy/webrtc-leak-test', icon: faUserShield },
    //         { name: 'IP Address Decoder', path: '/privacy/ip-decoder', icon: faGlobe },
    //         { name: 'Do Not Track Test', path: '/privacy/dnt-test', icon: faUserSecret },
    //         // { name: 'Ad Tracker Blocker', path: '/privacy/ad-blocker', icon: faAd },
    //         { name: 'VPN Test', path: '/privacy/vpn-test', icon: faShieldAlt },
    //         // { name: 'Privacy Policy Generator', path: '/privacy/policy-generator', icon: faFileContract },
    //         { name: 'Email Alias Generator', path: '/privacy/email-alias', icon: faAt },
    //         { name: 'Incognito Mode Detector', path: '/privacy/incognito-detector', icon: faUserNinja },
    //     ],
    // },
    utilities: {
        name: 'Utilities',
        path: '/utilities',
        icon: faFileCode,
        tools: [
            // { name: 'Regex Tester', path: '/utilities/regex-tester', icon: faSuperscript },
            // { name: 'GUID/UUID Validator', path: '/utilities/uuid-validator', icon: faCheckCircle },
            { name: 'Notes', path: '/utilities/notes', icon: faNoteSticky },
            // { name: 'CSS Minifier', path: '/utilities/css-minifier', icon: faCompressAlt },
            // { name: 'JavaScript Beautifier', path: '/utilities/js-beautifier', icon: faMagic },
            // { name: 'URL Parser', path: '/utilities/url-parser', icon: faLink },
            // { name: 'Color Picker', path: '/utilities/color-picker', icon: faEyeDropper },
            // { name: 'Timestamp Converter', path: '/utilities/timestamp-converter', icon: faClock },
            // { name: 'Unit Converter', path: '/utilities/unit-converter', icon: faRulerCombined },
            // { name: 'Checksum Generator', path: '/utilities/checksum-generator', icon: faCheckDouble },
        ],
    },
    // validators: {
    //     name: 'Validators',
    //     path: '/validators',
    //     icon: faFileCode,
    //     tools: [
    //         // { name: 'Email Validator', path: '/validators/email-validator', icon: faEnvelope },
    //         // { name: 'Credit Card Validator', path: '/validators/credit-card-validator', icon: faCreditCard },
    //         // { name: 'IBAN Validator', path: '/validators/iban-validator', icon: faMoneyCheckAlt },
    //         { name: 'URL Validator', path: '/validators/url-validator', icon: faGlobe },
    //         { name: 'JSON Validator', path: '/validators/json-validator', icon: faCode },
    //         { name: 'IP Address Validator', path: '/validators/ip-validator', icon: faMapMarkerAlt },
    //         // { name: 'Phone Number Validator', path: '/validators/phone-validator', icon: faPhone },
    //         // { name: 'Postal Code Validator', path: '/validators/postal-code-validator', icon: faMapPin },
    //         // { name: 'VAT Number Validator', path: '/validators/vat-validator', icon: faFileInvoiceDollar },
    //         { name: 'Password Strength Validator', path: '/validators/password-strength', icon: faUserLock },
    //     ],
    // },
};
