// src/utilities/Notes/NoteModal.tsx
import React, { useState } from 'react';
import styles from './NoteModal.module.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface NoteData {
    id: string;
    title: string;
    content: string;
    backgroundColor: string;
    fontColor: string;
    createdAt: string;
    updatedAt: string;
}

interface NoteModalProps {
    note: NoteData;
    onSave: (note: NoteData) => void;
    onCancel: () => void;
}

export const NoteModal: React.FC<NoteModalProps> = ({ note, onSave, onCancel }) => {
    const [editedTitle, setEditedTitle] = useState(note.title);
    const [editedContent, setEditedContent] = useState(note.content);

    const handleSave = () => {
        const updatedNote: NoteData = {
            ...note,
            title: editedTitle,
            content: editedContent,
            updatedAt: new Date().toISOString(), // Update the updatedAt timestamp
        };
        onSave(updatedNote);
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <h2>{note.title ? 'Edit Note' : 'Add Note'}</h2>
                </div>
                <div className={styles.modalBody}>
                    <input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        placeholder="Note Title"
                    />
                    <ReactQuill value={editedContent} onChange={setEditedContent} />
                </div>
                <div className={styles.modalFooter}>
                    <button onClick={handleSave} className={styles.saveButton}>
                        Save
                    </button>
                    <button onClick={onCancel} className={styles.cancelButton}>
                        Discard
                    </button>
                </div>
            </div>
        </div>
    );
};
