// src/components/Hero/index.tsx

import React, { useState, useEffect } from 'react';
import styles from './Hero.module.css';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface HeroProps {
  title: string;
  description: string;
  imageUrl: string;
  imageAlt?: string;
}

export const Hero: React.FC<HeroProps> = ({
                                            title,
                                            description,
                                            imageUrl,
                                            imageAlt = '',
                                          }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
    const savedState = localStorage.getItem('heroIsCollapsed');
    return savedState ? JSON.parse(savedState) : false;
  });

  const toggleCollapse = () => {
    setIsCollapsed((prev) => {
      localStorage.setItem('heroIsCollapsed', JSON.stringify(!prev));
      return !prev;
    });
  };

  useEffect(() => {
    // Update localStorage when component mounts or unmounts
    return () => {
      localStorage.setItem('heroIsCollapsed', JSON.stringify(isCollapsed));
    };
  }, [isCollapsed]);

  return (
    <div className={styles.heroPanel}>
      {isCollapsed ? (
        <div className={styles.heroCollapsed}>
          <div className={styles.heroContent}>
            <h1>{title}</h1>
          </div>
        </div>
      ) : (
        <div className={styles.heroSection}>
          <div className={styles.heroContent}>
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
          <div className={styles.heroImage}>
            <img src={imageUrl} alt={imageAlt} />
          </div>
        </div>
      )}

      <button onClick={toggleCollapse} className={styles.collapseButton}>
        {isCollapsed ? (
          <FontAwesomeIcon icon={faPlus} />
        ) : (
          <FontAwesomeIcon icon={faMinus} />
        )}
      </button>
    </div>
  );
};
