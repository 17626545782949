// src/tools/Comparator/JSONComparator/index.tsx

import React, {useState, useRef} from 'react';
import styles from './JSONComparator.module.css';
import {diffLines, diffWords} from 'diff';
import {Hero} from "../../../components/Hero";
import {SEO} from "../../../components/SEO";

interface LineObject {
    text: string;
    added?: boolean;
    removed?: boolean;
    modified?: boolean;
    unchanged?: boolean;
    blank?: boolean;
}

export const JSONComparator: React.FC = () => {
    const [leftJSON, setLeftJSON] = useState('');
    const [rightJSON, setRightJSON] = useState('');
    const [leftFormattedJSON, setLeftFormattedJSON] = useState<LineObject[]>([]);
    const [rightFormattedJSON, setRightFormattedJSON] = useState<LineObject[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const leftOutputRef = useRef<HTMLDivElement>(null);
    const rightOutputRef = useRef<HTMLDivElement>(null);

    // Synchronization flags to prevent infinite loops
    const isSyncingLeftScroll = useRef(false);
    const isSyncingRightScroll = useRef(false);

    const handleLeftScroll = () => {
        if (
            leftOutputRef.current &&
            rightOutputRef.current &&
            !isSyncingLeftScroll.current
        ) {
            isSyncingRightScroll.current = true;
            rightOutputRef.current.scrollTop = leftOutputRef.current.scrollTop;
            rightOutputRef.current.scrollLeft = leftOutputRef.current.scrollLeft;
        }
        isSyncingLeftScroll.current = false;
    };

    const handleRightScroll = () => {
        if (
            leftOutputRef.current &&
            rightOutputRef.current &&
            !isSyncingRightScroll.current
        ) {
            isSyncingLeftScroll.current = true;
            leftOutputRef.current.scrollTop = rightOutputRef.current.scrollTop;
            leftOutputRef.current.scrollLeft = rightOutputRef.current.scrollLeft;
        }
        isSyncingRightScroll.current = false;
    };

    const formatAndCompareJSON = () => {
        try {
            const leftParsed = JSON.parse(leftJSON);
            const rightParsed = JSON.parse(rightJSON);
            setErrorMessage('');

            const leftFormatted = JSON.stringify(leftParsed, null, 2);
            const rightFormatted = JSON.stringify(rightParsed, null, 2);

            const diffs = diffLines(leftFormatted, rightFormatted);

            const leftResult: LineObject[] = [];
            const rightResult: LineObject[] = [];

            diffs.forEach((part) => {
                const lines = part.value.split('\n');
                // Remove the last empty string if it exists
                if (lines[lines.length - 1] === '') {
                    lines.pop();
                }

                if (part.added) {
                    // Lines added in right JSON
                    lines.forEach((line) => {
                        rightResult.push({text: line, added: true});
                        leftResult.push({text: '', blank: true});
                    });
                } else if (part.removed) {
                    // Lines removed from left JSON
                    lines.forEach((line) => {
                        leftResult.push({text: line, removed: true});
                        rightResult.push({text: '', blank: true});
                    });
                } else {
                    // Lines unchanged or modified
                    lines.forEach((line) => {
                        const leftLine = line;
                        const rightLine = line;

                        // For each unchanged line, check for modifications at word level
                        const wordDiffs = diffWords(leftLine, rightLine);
                        if (wordDiffs.some((wd) => wd.added || wd.removed)) {
                            // Line has modifications
                            const leftLineHTML = wordDiffs
                                .filter((wd) => !wd.added)
                                .map((wd) =>
                                    wd.removed
                                        ? `<span class="${styles.removedText}">${wd.value}</span>`
                                        : wd.value
                                )
                                .join('');
                            const rightLineHTML = wordDiffs
                                .filter((wd) => !wd.removed)
                                .map((wd) =>
                                    wd.added
                                        ? `<span class="${styles.addedText}">${wd.value}</span>`
                                        : wd.value
                                )
                                .join('');
                            leftResult.push({text: leftLineHTML, modified: true});
                            rightResult.push({text: rightLineHTML, modified: true});
                        } else {
                            // Line is unchanged
                            leftResult.push({text: leftLine, unchanged: true});
                            rightResult.push({text: rightLine, unchanged: true});
                        }
                    });
                }
            });

            setLeftFormattedJSON(leftResult);
            setRightFormattedJSON(rightResult);
        } catch {
            setLeftFormattedJSON([]);
            setRightFormattedJSON([]);
            setErrorMessage('Invalid JSON input');
        }
    };

    return (
        <div className={styles.jsonComparator}>
            {/* SEO Meta Tags */}
            <SEO
                title="JSON Comparator | CyberZero AI"
                description="Compare and analyze JSON data with CyberZero AI's free JSON Comparator tool."
                keywords="JSON Comparator, JSON Diff, CyberZero AI, Developer Tools, Compare JSON, JSON Differences"
                author="Karthik Ramesh"
                image="/assets/json/comparator/json-comparator-banner.png"
                url="https://apps.cyberzero.ai/json/comparator"
                ogTitle="JSON Comparator | CyberZero AI"
                ogDescription="Compare and analyze JSON data with CyberZero AI's free JSON Comparator tool."
                ogImage="/assets/json/comparator/json-comparator-banner.png"
                ogUrl="https://apps.cyberzero.ai/json/comparator"
                twitterTitle="JSON Comparator | CyberZero AI"
                twitterDescription="Compare and analyze JSON data with CyberZero AI's free JSON Comparator tool."
                twitterImage="/assets/json/comparator/json-comparator-banner.png"
                canonical="https://apps.cyberzero.ai/json/comparator"
                structuredData={JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "WebApplication",
                    "name": "JSON Comparator",
                    "url": "https://apps.cyberzero.ai/json/comparator",
                    "description": "Compare and analyze JSON data with CyberZero AI's free JSON Comparator tool.",
                    "applicationCategory": "DeveloperApplication",
                    "operatingSystem": "All",
                    "author": {
                        "@type": "Person",
                        "name": "Karthik Ramesh"
                    },
                    "image": "/assets/json/comparator/json-comparator-banner.png"
                })}
                meta={[
                    { charset: 'utf-8' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:locale', content: 'en_US' },
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:site', content: '@CyberZero.AI' },
                    { name: 'twitter:creator', content: '@CyberZero.AI' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'theme-color', content: '#5e17eb' },
                    { name: 'application-name', content: 'CyberZero AI' },
                    { name: 'apple-mobile-web-app-capable', content: 'yes' },
                ]}
            />

            {/* Hero Section */}
            <Hero
                title="JSON Comparator"
                description="Compare and analyze your JSON data effortlessly."
                imageUrl="/assets/json/comparator/json-comparator-banner.png"
                imageAlt="JSON Comparator"
            />

            {/* JSON Comparator Form */}
            <div className={styles.comparatorForm}>
                {/* Input Areas */}
                <div className={styles.inputSection}>
          <textarea
              className={styles.textArea}
              value={leftJSON}
              onChange={(e) => setLeftJSON(e.target.value)}
              placeholder="Enter left JSON here"
          ></textarea>
                    <textarea
                        className={styles.textArea}
                        value={rightJSON}
                        onChange={(e) => setRightJSON(e.target.value)}
                        placeholder="Enter right JSON here"
                    ></textarea>
                </div>
                <button className={styles.compareButton} onClick={formatAndCompareJSON}>
                    Compare JSON
                </button>

                {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

                {leftFormattedJSON.length > 0 && rightFormattedJSON.length > 0 && (
                    <div className={styles.outputSection}>
                        <div
                            className={styles.outputArea}
                            ref={leftOutputRef}
                            onScroll={handleLeftScroll}
                        >
                            {leftFormattedJSON.map((lineObj, index) => (
                                <div
                                    key={index}
                                    className={`${styles.line} ${
                                        lineObj.added
                                            ? styles.added
                                            : lineObj.removed
                                                ? styles.removed
                                                : lineObj.modified
                                                    ? styles.modified
                                                    : lineObj.blank
                                                        ? styles.blank
                                                        : ''
                                    }`}
                                >
                                    <span className={styles.lineNumber}>{index + 1}</span>
                                    <span
                                        className={styles.lineContent}
                                    >
                                        <span>{lineObj.text.match(/^\s*/)?.[0] || ''}</span>
                                        <span className={styles.lineContentText}>{lineObj.text.trimStart()}</span>
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div
                            className={styles.outputArea}
                            ref={rightOutputRef}
                            onScroll={handleRightScroll}
                        >
                            {rightFormattedJSON.map((lineObj, index) => (
                                <div
                                    key={index}
                                    className={`${styles.line} ${
                                        lineObj.added
                                            ? styles.added
                                            : lineObj.removed
                                                ? styles.removed
                                                : lineObj.modified
                                                    ? styles.modified
                                                    : lineObj.blank
                                                        ? styles.blank
                                                        : ''
                                    }`}
                                >
                                    <span className={styles.lineNumber}>{index + 1}</span>
                                    <span
                                        className={styles.lineContent}
                                        dangerouslySetInnerHTML={{__html: lineObj.text}}
                                    ></span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
