// src/utilities/Notes/index.tsx

import React, { useState } from 'react';
import styles from './Notes.module.css';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faFolderOpen,
    faChevronDown,
    faChevronUp,
    faArrowUp,
    faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { Note } from './Note';
import { NoteModal } from './NoteModal';
import { Hero } from '../../../components/Hero';
import { SEO } from '../../../components/SEO';

interface NoteData {
    id: string;
    title: string;
    content: string;
    backgroundColor: string;
    fontColor: string;
    createdAt: string;
    updatedAt: string;
}

export const UtilitiesNotes: React.FC = () => {
    const [notes, setNotes] = useState<NoteData[]>([]);
    const [fileHandle, setFileHandle] = useState<FileSystemFileHandle | null>(null);
    const [isFileOpen, setIsFileOpen] = useState(false);
    const [expandAll, setExpandAll] = useState(false);
    const [isEditingNewNote, setIsEditingNewNote] = useState(false);

    const handleCreateNewNotesGroup = async () => {
        try {
            if (window.showSaveFilePicker) {
                const opts: SaveFilePickerOptions = {
                    suggestedName: 'notes.cz.json',
                    types: [
                        {
                            description: 'JSON Files',
                            accept: { 'application/json': ['.json'] },
                        },
                    ],
                };
                const handle = await window.showSaveFilePicker(opts);
                setFileHandle(handle);
                setNotes([]);
                setIsFileOpen(true);
                toast.success('New Notes Group created.');
            } else {
                toast.error('File System Access API is not available in your browser.');
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to create new notes group.');
        }
    };

    const handleOpenExistingNotesGroup = async (fileHandle?: FileSystemFileHandle) => {
        try {
            if (window.showOpenFilePicker || fileHandle) {
                let handle = fileHandle;
                if (!handle) {
                    if (window.showOpenFilePicker) {
                        const handles = await window.showOpenFilePicker({
                            types: [
                                {
                                    description: 'JSON Files',
                                    accept: { 'application/json': ['.json'] },
                                },
                            ],
                            multiple: false,
                        });

                        if (handles.length !== 1) {
                            toast.error('Please select only one .json file.');
                            return;
                        }

                        handle = handles[0];
                    } else {
                        throw new Error('File System Access API is not available.');
                    }
                }

                // Check file extension
                const file = await handle.getFile();
                if (!file.name.toLowerCase().endsWith('.json')) {
                    toast.error('Invalid file type. Please select a .json file.');
                    return;
                }

                const contents = await file.text();
                const notesData = JSON.parse(contents) as NoteData[];
                notesData.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
                setNotes(notesData);
                setFileHandle(handle);
                setIsFileOpen(true);
                toast.success('Notes Group opened.');
            } else {
                toast.error('File System Access API is not available in your browser.');
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to open notes group.');
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    };

    const handleAddNote = () => {
        setIsEditingNewNote(true); // Open the modal to create a new note
    };

    const handleUpdateNote = (updatedNote: NoteData) => {
        const updatedNotes = notes.map((note) =>
            note.id === updatedNote.id ? updatedNote : note
        );
        updatedNotes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
        setNotes(updatedNotes);
        handleSave(updatedNotes); // Save the notes after updating
    };

    const handleDeleteNote = (id: string) => {
        if (window.confirm('Are you sure you want to delete this note?')) {
            const updatedNotes = notes.filter((note) => note.id !== id);
            updatedNotes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            setNotes(updatedNotes);
            handleSave(updatedNotes); // Save the notes after deleting
        }
    };

    const handleExpandAll = () => {
        setExpandAll(!expandAll);
    };

    const handleSave = async (updatedNotes: NoteData[]) => {
        if (fileHandle) {
            try {
                const writable = await fileHandle.createWritable();
                await writable.write(JSON.stringify(updatedNotes, null, 2));
                await writable.close();
                toast.success('Notes saved.');
            } catch (error) {
                console.error(error);
                toast.error('Failed to save notes.');
            }
        } else {
            toast.error('No file handle available.');
        }
    };

    return (
        <div className={styles.notesApp}>
            {/* SEO Meta Tags */}
            <SEO
                title="Notes App | CyberZero AI"
                description="Manage your notes with CyberZero AI's free Notes App tool. Enhance your productivity today."
                keywords="Notes App, Note Taking, CyberZero AI, Productivity Tools, Manage Notes, Digital Notebook"
                author="Karthik Ramesh"
                image="/assets/utilities/notes/notes-banner.png"
                url="https://apps.cyberzero.ai/utilities/notes"
                ogTitle="Notes App | CyberZero AI"
                ogDescription="Manage your notes with CyberZero AI's free Notes App tool."
                ogImage="/assets/utilities/notes/notes-banner.png"
                ogUrl="https://apps.cyberzero.ai/utilities/notes"
                twitterTitle="Notes App | CyberZero AI"
                twitterDescription="Manage your notes with CyberZero AI's free Notes App tool."
                twitterImage="/assets/utilities/notes/notes-banner.png"
                canonical="https://apps.cyberzero.ai/utilities/notes"
                structuredData={JSON.stringify({
                    '@context': 'http://schema.org',
                    '@type': 'WebApplication',
                    name: 'Notes App',
                    url: 'https://apps.cyberzero.ai/utilities/notes',
                    description:
                        "Manage your notes with CyberZero AI's free Notes App tool. Enhance your productivity today.",
                    applicationCategory: 'ProductivityApplication',
                    operatingSystem: 'All',
                    author: {
                        '@type': 'Person',
                        name: 'Karthik Ramesh',
                    },
                    image: '/assets/utilities/notes/notes-banner.png',
                    publisher: {
                        '@type': 'Organization',
                        name: 'CyberZero AI',
                        logo: {
                            '@type': 'ImageObject',
                            url: '/assets/logo.png',
                        },
                    },
                })}
                meta={[
                    { charset: 'utf-8' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:locale', content: 'en_US' },
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:site', content: '@CyberZero.AI' },
                    { name: 'twitter:creator', content: '@CyberZero.AI' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'theme-color', content: '#5e17eb' },
                    { name: 'application-name', content: 'CyberZero AI' },
                    { name: 'apple-mobile-web-app-capable', content: 'yes' },
                ]}
            />

            {/* Hero Section */}
            <Hero
                title="Notes App"
                description="Create and manage your notes easily."
                imageUrl="/assets/utilities/notes/notes-banner.png"
                imageAlt="Notes App"
            />

            {/* Main Content */}
            <div className={styles.notesAppContent}>
                {!isFileOpen ? (
                    <div className={styles.initialOptions}>
                        <div className={`${styles.optionBox} ${styles.actionBox}`} onClick={handleCreateNewNotesGroup}>
                            <div className={styles.iconContainer}>
                                <FontAwesomeIcon icon={faPlus} size="4x" />
                            </div>
                            <p>Create New Notes</p>
                        </div>
                        <div
                            className={`${styles.optionBox} ${styles.actionBox}`}
                            onClick={() => handleOpenExistingNotesGroup()}
                        >
                            <div className={styles.iconContainer}>
                                <FontAwesomeIcon icon={faFolderOpen} size="4x" />
                            </div>
                            <p>Open Existing Notes</p>
                        </div>
                    </div>
                ) : (
                    <div className={styles.notesEditor}>
                        <div className={styles.editorHeader}>
                            <button onClick={handleAddNote}>
                                <FontAwesomeIcon icon={faPlus} /> Add Note
                            </button>
                            <button onClick={handleExpandAll}>
                                <FontAwesomeIcon
                                    icon={expandAll ? faChevronUp : faChevronDown}
                                />{' '}
                                {expandAll ? 'Collapse All' : 'Expand All'}
                            </button>
                        </div>
                        <div className={styles.notesList}>
                            {notes.map((note) => (
                                <Note
                                    key={note.id}
                                    note={note}
                                    onUpdateNote={handleUpdateNote}
                                    onDeleteNote={handleDeleteNote}
                                    expandAll={expandAll}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>

            {/* Note Modal for Adding New Note */}
            {isEditingNewNote && (
                <NoteModal
                    note={{
                        id: Date.now().toString(),
                        title: '',
                        content: '',
                        backgroundColor: '#ffffff',
                        fontColor: '#000000',
                        createdAt: new Date().toISOString(),
                        updatedAt: new Date().toISOString(),
                    }}
                    onSave={(note) => {
                        const updatedNotes = [...notes, note];
                        updatedNotes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
                        setNotes(updatedNotes);
                        handleSave([...notes, note]);
                        setIsEditingNewNote(false);
                    }}
                    onCancel={() => setIsEditingNewNote(false)}
                />
            )}

            {/* Scroll Buttons */}
            <div className={styles.scrollButtons}>
                <button onClick={scrollToTop} title="Scroll to Top">
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
                <button onClick={scrollToBottom} title="Scroll to Bottom">
                    <FontAwesomeIcon icon={faArrowDown} />
                </button>
            </div>
        </div>
    );
};
