// src/tools/Json/Decoder/CollapsibleJson.tsx

import React, { useState, useEffect } from 'react';
import styles from './CollapsibleJson.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

type JSONValue =
    | string
    | number
    | boolean
    | null
    | { [key: string]: JSONValue }
    | JSONValue[];

type ExpandCollapseAction = {
    action: 'expand' | 'collapse';
    id: number;
};

interface TreeNode {
    lineNumber: number;
    depth: number;
    keyName?: string;
    isLastElement: boolean;
    data: JSONValue | null;
    type: 'object' | 'array' | 'primitive';
    nodeType: 'opening' | 'closing' | 'value';
    children?: TreeNode[];
}

interface CollapsibleJSONProps {
    data: JSONValue;
    expandAll: ExpandCollapseAction | null;
    lineNumbers?: boolean;
}

export const CollapsibleJSON: React.FC<CollapsibleJSONProps> = ({
                                                                    data,
                                                                    expandAll,
                                                                    lineNumbers = false,
                                                                }) => {
    const [treeData, setTreeData] = useState<TreeNode | null>(null);

    useEffect(() => {
        let lineNumber = 1;

        const buildTree = (
            data: JSONValue,
            depth: number,
            keyName: string | undefined,
            isLastElement: boolean
        ): TreeNode => {
            const nodeType =
                data !== null && typeof data === 'object'
                    ? Array.isArray(data)
                        ? 'array'
                        : 'object'
                    : 'primitive';

            const node: TreeNode = {
                lineNumber: lineNumber++,
                depth,
                keyName,
                isLastElement,
                data,
                type: nodeType,
                nodeType: nodeType === 'primitive' ? 'value' : 'opening',
                children: [],
            };

            if (nodeType === 'object' || nodeType === 'array') {
                const children: TreeNode[] = [];

                if (nodeType === 'array') {
                    (data as JSONValue[]).forEach((item, index) => {
                        const isLast = index === (data as JSONValue[]).length - 1;
                        const childNode = buildTree(item, depth + 1, undefined, isLast);
                        children.push(childNode);
                    });
                } else {
                    const keys = Object.keys(data as object);
                    keys.forEach((key, index) => {
                        const isLast = index === keys.length - 1;
                        const childNode = buildTree(
                            (data as { [key: string]: JSONValue })[key],
                            depth + 1,
                            key,
                            isLast
                        );
                        children.push(childNode);
                    });
                }

                // Closing line
                const closingNode: TreeNode = {
                    lineNumber: lineNumber++,
                    depth,
                    isLastElement,
                    data: null,
                    type: nodeType,
                    nodeType: 'closing',
                };

                node.children = [...children, closingNode];
            }

            return node;
        };

        const tree = buildTree(data, 0, undefined, true);
        setTreeData(tree);
    }, [data]);

    return (
        <div className={styles.collapsibleJSON}>
            {treeData && (
                <JSONNode
                    node={treeData}
                    expandAll={expandAll}
                    lineNumbers={lineNumbers}
                />
            )}
        </div>
    );
};

interface JSONNodeProps {
    node: TreeNode;
    expandAll: ExpandCollapseAction | null;
    lineNumbers: boolean;
}

const JSONNode: React.FC<JSONNodeProps> = ({
                                               node,
                                               expandAll,
                                               lineNumbers,
                                           }) => {
    const {
        data,
        depth,
        keyName,
        isLastElement,
        lineNumber,
        type,
        nodeType,
        children,
    } = node;

    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    useEffect(() => {
        if (expandAll) {
            if (expandAll.action === 'expand') {
                setIsCollapsed(false);
            } else if (expandAll.action === 'collapse') {
                setIsCollapsed(true);
            }
        }
    }, [expandAll]);

    const indentStyle = { paddingLeft: depth * 20 + 'px' };

    const renderLineNumber = () => (
        <span className={styles.lineNumber}>{lineNumber}</span>
    );

    const elements: React.ReactNode[] = [];

    if (nodeType === 'opening') {
        const isArray = type === 'array';
        const openingBracket = isArray ? '[' : '{';
        const count = isArray
            ? `${(data as JSONValue[]).length} item${
                (data as JSONValue[]).length !== 1 ? 's' : ''
            }`
            : `${Object.keys(data as object).length} key${
                Object.keys(data as object).length !== 1 ? 's' : ''
            }`;

        elements.push(
            <div className={styles.line} key={`line-${lineNumber}`}>
                {lineNumbers && renderLineNumber()}
                <div className={styles.content} style={indentStyle}>
          <span
              onClick={() => setIsCollapsed(!isCollapsed)}
              className={styles.collapsibleToggle}
          >
            <FontAwesomeIcon
                icon={isCollapsed ? faChevronRight : faChevronDown}
            />
          </span>
                    {keyName && <span className={styles.keyName}>"{keyName}": </span>}
                    <span className={styles.bracket}>{openingBracket}</span>
                    <span className={styles.itemCount}>  {'//' + count}</span>
                </div>
            </div>
        );

        if (!isCollapsed && children) {
            for (const child of children) {
                elements.push(
                    <JSONNode
                        key={`node-${child.lineNumber}`}
                        node={child}
                        expandAll={expandAll}
                        lineNumbers={lineNumbers}
                    />
                );
            }
        } else if (isCollapsed && children) {
            // If collapsed, render placeholders for line numbers
            for (const child of children) {
                elements.push(
                    <div
                        className={`${styles.line} ${styles.hiddenLine}`}
                        key={`line-${child.lineNumber}-hidden`}
                    >
                        {lineNumbers && (
                            <span className={styles.lineNumber}>{child.lineNumber}</span>
                        )}
                        <div className={styles.content} style={{ display: 'none' }}>
                            {/* Hidden content */}
                        </div>
                    </div>
                );
            }
        }
    } else if (nodeType === 'closing') {
        const isArray = type === 'array';
        const closingBracket = isArray ? ']' : '}';

        elements.push(
            <div className={styles.line} key={`line-${lineNumber}`}>
                {lineNumbers && renderLineNumber()}
                <div className={styles.content} style={indentStyle}>
                    <span className={styles.bracket}>{closingBracket}</span>
                    {!isLastElement && ','}
                </div>
            </div>
        );
    } else if (nodeType === 'value') {
        elements.push(
            <div className={styles.line} key={`line-${lineNumber}`}>
                {lineNumbers && renderLineNumber()}
                <div className={styles.content} style={indentStyle}>
                    {keyName && <span className={styles.keyName}>"{keyName}": </span>}
                    <span className={styles.value}>{JSON.stringify(data)}</span>
                    {!isLastElement && ','}
                </div>
            </div>
        );
    }

    return <>{elements}</>;
};
