// src/tools/Formatter/JSONFormatter/CollapsibleJSON.tsx
import React, { useState, useEffect } from 'react';
import styles from './CollapsibleJson.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

type JSONValue =
    | string
    | number
    | boolean
    | null
    | { [key: string]: JSONValue }
    | JSONValue[];

type ExpandCollapseAction = {
    action: 'expand' | 'collapse';
    id: number;
}

interface CollapsibleJSONProps {
    data: JSONValue;
    expandAll: ExpandCollapseAction | null;
}

export const CollapsibleJSON: React.FC<CollapsibleJSONProps> = ({
                                                                    data,
                                                                    expandAll,
                                                                }) => {
    return (
        <div className={styles.collapsibleJSON}>
            <JSONNode data={data} depth={0} expandAll={expandAll} />
        </div>
    );
};

interface JSONNodeProps {
    data: JSONValue;
    depth: number;
    keyName?: string;
    expandAll: ExpandCollapseAction | null;
}

const JSONNode: React.FC<JSONNodeProps> = ({
                                               data,
                                               depth,
                                               keyName,
                                               expandAll,
                                           }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [lastExpandAllId, setLastExpandAllId] = useState<number | null>(null);

    useEffect(() => {
        if (expandAll && expandAll.id !== lastExpandAllId) {
            if (expandAll.action === 'expand') {
                setCollapsed(false);
            } else if (expandAll.action === 'collapse') {
                setCollapsed(true);
            }
            setLastExpandAllId(expandAll.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandAll]);

    const isObject =
        data !== null && typeof data === 'object' && !Array.isArray(data);
    const isArray = Array.isArray(data);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const indent = { marginLeft: depth * 20 + 'px' };

    if (isArray) {
        const dataArray = data as JSONValue[];
        const openingBracket = '[';
        const closingBracket = ']';

        return (
            <div style={indent}>
        <span onClick={toggleCollapse} className={styles.collapsibleToggle}>
          <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronDown} />
        </span>{' '}
                {keyName && <span className={styles.keyName}>"{keyName}": </span>}
                <span className={styles.bracket}>{openingBracket}</span>
                {!collapsed && (
                    <div className={styles.collapsibleContent}>
                        {dataArray.map((item, index) => (
                            <JSONNode
                                key={index}
                                data={item}
                                depth={depth + 1}
                                keyName={undefined}
                                expandAll={expandAll}
                            />
                        ))}
                    </div>
                )}
                <span className={styles.bracket}>{closingBracket}</span>
                {depth > 0 && ','}
            </div>
        );
    } else if (isObject) {
        const dataObject = data as { [key: string]: JSONValue };
        const keys = Object.keys(dataObject);
        const openingBracket = '{';
        const closingBracket = '}';

        return (
            <div style={indent}>
        <span onClick={toggleCollapse} className={styles.collapsibleToggle}>
          <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronDown} />
        </span>{' '}
                {keyName && <span className={styles.keyName}>"{keyName}": </span>}
                <span className={styles.bracket}>{openingBracket}</span>
                {!collapsed && (
                    <div className={styles.collapsibleContent}>
                        {keys.map((key) => (
                            <JSONNode
                                key={key}
                                data={dataObject[key]}
                                depth={depth + 1}
                                keyName={key}
                                expandAll={expandAll}
                            />
                        ))}
                    </div>
                )}
                <span className={styles.bracket}>{closingBracket}</span>
                {depth > 0 && ','}
            </div>
        );
    } else {
        return (
            <div style={indent}>
                {keyName && <span className={styles.keyName}>"{keyName}": </span>}
                <span className={styles.value}>{JSON.stringify(data)}</span>
                {depth > 0 && ','}
            </div>
        );
    }
};
