// src/utilities/Notes/Note.tsx

import React, { useState, useEffect, useRef } from 'react';
import styles from './Note.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faEdit,
    faTrash,
    faPalette,
    faChevronDown,
    faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { SketchPicker, ColorResult } from 'react-color';
import { toast } from 'react-toastify';
import { NoteModal } from './NoteModal';

interface NoteData {
    id: string;
    title: string;
    content: string;
    backgroundColor: string;
    fontColor: string;
    createdAt: string;
    updatedAt: string;
}

interface NoteProps {
    note: NoteData;
    onUpdateNote: (updatedNote: NoteData) => void;
    onDeleteNote: (id: string) => void;
    expandAll: boolean;
}

export const Note: React.FC<NoteProps> = ({
                                              note,
                                              onUpdateNote,
                                              onDeleteNote,
                                              expandAll,
                                          }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);

    // State for current colors
    const [noteColor, setNoteColor] = useState(note.backgroundColor);
    const [fontColor, setFontColor] = useState(note.fontColor);

    // State for temporary colors in the color picker
    const [tempNoteColor, setTempNoteColor] = useState(note.backgroundColor);
    const [tempFontColor, setTempFontColor] = useState(note.fontColor);

    const colorPickerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setIsExpanded(expandAll);
    }, [expandAll]);

    useEffect(() => {
        // Update colors if the note prop changes
        setNoteColor(note.backgroundColor);
        setFontColor(note.fontColor);
    }, [note.backgroundColor, note.fontColor]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                colorPickerRef.current &&
                !colorPickerRef.current.contains(event.target as Node)
            ) {
                handleDiscardColors();
            }
        };

        if (showColorPicker) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showColorPicker]);

    const handleCopy = async () => {
        try {
            const htmlContent = note.content;

            const textContent = htmlContent
                .replace(/<br\s*\/?>/gi, '\n')
                .replace(/<\/(p|div|h[1-6])>/gi, '\n')
                .replace(/<[^>]+>/g, '') // Remove remaining HTML tags
                .replace(/\n{2,}/g, '\n'); // Remove multiple consecutive line breaks

            const htmlBlob = new Blob([htmlContent], { type: 'text/html' });
            const textBlob = new Blob([textContent], { type: 'text/plain' });

            if (navigator.clipboard && navigator.clipboard.write) {
                await navigator.clipboard.write([
                    new ClipboardItem({
                        'text/html': htmlBlob,
                        'text/plain': textBlob,
                    }),
                ]);
                toast.success('Note copied to clipboard!');
            } else {
                // Fallback for older browsers
                const textarea = document.createElement('textarea');
                textarea.value = textContent;
                document.body.appendChild(textarea);
                textarea.focus();
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
                toast.success('Note copied to clipboard!');
            }
        } catch (error) {
            console.error('Failed to copy: ', error);
            toast.error('Failed to copy note.');
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleDelete = () => {
        onDeleteNote(note.id);
    };

    const handleColorPicker = () => {
        // Initialize temporary colors
        setTempNoteColor(noteColor);
        setTempFontColor(fontColor);
        setShowColorPicker(!showColorPicker);
    };

    const handleBackgroundColorChange = (color: ColorResult) => {
        setTempNoteColor(color.hex);
    };

    const handleFontColorChange = (color: ColorResult) => {
        setTempFontColor(color.hex);
    };

    const handleSaveColors = () => {
        setNoteColor(tempNoteColor);
        setFontColor(tempFontColor);
        onUpdateNote({
            ...note,
            backgroundColor: tempNoteColor,
            fontColor: tempFontColor,
        });
        setShowColorPicker(false);
    };

    const handleDiscardColors = () => {
        setTempNoteColor(noteColor);
        setTempFontColor(fontColor);
        setShowColorPicker(false);
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleHeaderClick = () => {
        toggleExpand();
    };

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div
            className={styles.note}
            style={{
                backgroundColor: showColorPicker ? tempNoteColor : noteColor,
                color: showColorPicker ? tempFontColor : fontColor,
            }}
        >
            <div className={styles.noteHeader} onClick={handleHeaderClick}>
                <div className={styles.noteTitle}>
                    <h3>{note.title || 'Untitled Note'}</h3>
                </div>
                <div className={styles.noteActions}>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCopy();
                        }}
                        title="Copy"
                    >
                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit();
                        }}
                        title="Edit"
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDelete();
                        }}
                        title="Delete"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleColorPicker();
                        }}
                        title="Color Picker"
                    >
                        <FontAwesomeIcon icon={faPalette} />
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleExpand();
                        }}
                        title="Expand/Collapse"
                    >
                        <FontAwesomeIcon
                            icon={isExpanded ? faChevronUp : faChevronDown}
                        />
                    </button>
                </div>
            </div>

            {showColorPicker && (
                <div className={styles.colorPicker} ref={colorPickerRef}>
                    <div className={styles.colorPickerPanel}>
                        <div className={styles.colorPickerSection}>
                            <p>Background Color:</p>
                            <SketchPicker
                                color={tempNoteColor}
                                onChange={handleBackgroundColorChange}
                            />
                        </div>
                        <div className={styles.colorPickerSection}>
                            <p>Font Color:</p>
                            <SketchPicker
                                color={tempFontColor}
                                onChange={handleFontColorChange}
                            />
                        </div>
                    </div>
                    <div className={styles.colorPickerActions}>
                        <button onClick={handleSaveColors} className={styles.saveButton}>
                            Save
                        </button>
                        <button
                            onClick={handleDiscardColors}
                            className={styles.cancelButton}
                        >
                            Discard
                        </button>
                    </div>
                </div>
            )}

            {isExpanded && (
                <div className={styles.noteContent}>
                    <div dangerouslySetInnerHTML={{ __html: note.content }} />
                    <div className={styles.noteDates}>
                        <small className={styles.noteDatesContent}>Created: {formatDate(note.createdAt)}</small>
                        <small className={styles.noteDatesContent}>Last updated: {formatDate(note.updatedAt)}</small>
                    </div>
                </div>
            )}

            {isEditing && (
                <NoteModal
                    note={note}
                    onSave={(updatedNote) => {
                        onUpdateNote(updatedNote);
                        setIsEditing(false);
                    }}
                    onCancel={() => setIsEditing(false)}
                />
            )}
        </div>
    );
};
