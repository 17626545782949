// Adjust the path according to your project structure
export const CommonPasswords: string[] = [
    'password',
    '123456',
    '123456789',
    'qwerty',
    'abc123',
    'password1',
    'iloveyou',
    '111111',
    '123123',
    'admin',
    'P@ssw0rd',
    // Add more common passwords as needed
];